.tableFooter {
  background-color: rgba(19, 143, 255, 0.1);
  padding: 8px 0px;
  width: 100%;
  font-weight: 500;
  /* text-align: left; */
  font-size: 16px;
  color: #2c3e50;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  /* display: flex;
  align-items: center;
  justify-content: center;
  */
  position: relative;
} 

.button {
  border: none;
  padding: 7px 14px;
  border-radius: 10px;
  cursor: pointer;
  margin-right: 4px;
  margin-left: 4px;
}

.activeButton {
  color: white;
  background: #138FFF;
}

.inactiveButton {
  color: #2c3e50;
  background: #f9f9f9;
}


.tableFooter_cus{
  /* background-color: rgba(19, 143, 255, 0.1); */
  padding: 8px 0px;
  width: 100%;
  height: 60px;
  font-weight: 500;
  /* text-align: left; */
  font-size: 16px;
  color: #2c3e50;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.button_cus{
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.nav_container{
  display: flex;
  position: absolute;
  right: 60px;
  margin-left: auto;
}

.temp_spacer{
  width: 52px;
}